import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpRequest,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { combineLatest, from, of, mergeMap } from 'rxjs';
import { routes } from './app.routes';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        realm: 'quickstart',
        url: 'http://localhost:80',
        clientId: 'authz-servlet',
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
        redirectUri: 'http://localhost:8080',
        silentCheckSsoRedirectUri: `${window.location.origin}/assets/silent-check-sso.html`,
      },
      enableBearerInterceptor: true, // we need to check if not already 'true' by default
    });
}

/*
  The original KeycloakBearerInterceptor interceptor is not working when importing the KeycloakAngularModule.
  This is a copy of the implementation at node_modules\keycloak-angular\fesm2022\keycloak-angular.mjs which is explicitly provided
  in our providers array passed to the appConfig.
  TODO: Remove this implementation of KeycloakBearerInterceptor after https://birkle-it.atlassian.net/browse/DEVHUB-282.
*/
/* eslint-disable */
class KeycloakBearerInterceptor {
  keycloak: KeycloakService;

  constructor(keycloak: KeycloakService) {
    this.keycloak = keycloak;
  }

  async conditionallyUpdateToken(req: HttpRequest<unknown>) {
    if (this.keycloak.shouldUpdateToken(req)) {
      return await this.keycloak.updateToken();
    }
    return true;
  }

  isUrlExcluded({ method, url }: any, { urlPattern, httpMethods }: any) {
    const httpTest =
      httpMethods.length === 0 ||
      httpMethods.join().indexOf(method.toUpperCase()) > -1;
    const urlTest = urlPattern.test(url);
    return httpTest && urlTest;
  }

  intercept(req: HttpRequest<unknown>, next: { handle: (arg0: any) => any }) {
    console.log('Keycloak Interceptor triggered for:', req.url);
    const { enableBearerInterceptor, excludedUrls } = this.keycloak;
    if (!enableBearerInterceptor) {
      console.log('is bearer interceptor enabled?', enableBearerInterceptor);
      return next.handle(req);
    }
    const shallPass =
      !this.keycloak.shouldAddToken(req) ||
      excludedUrls.findIndex((item) => this.isUrlExcluded(req, item)) > -1;
    if (shallPass) {
      console.log('should add token?', shallPass);
      return next.handle(req);
    }
    return combineLatest([
      from(this.conditionallyUpdateToken(req)),
      of(this.keycloak.isLoggedIn()),
    ]).pipe(
      mergeMap(([_, isLoggedIn]) =>
        isLoggedIn
          ? this.handleRequestWithTokenHeader(req, next)
          : next.handle(req),
      ),
    );
  }

  handleRequestWithTokenHeader(
    req: HttpRequest<unknown>,
    next: { handle: any },
  ) {
    return this.keycloak.addTokenToHeader(req.headers).pipe(
      mergeMap((headersWithBearer) => {
        console.log('adding token to request headers');
        const kcReq = req.clone({ headers: headersWithBearer });
        return next.handle(kcReq);
      }),
    );
  }
}
/* eslint-enable */

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      BrowserModule,
      ReactiveFormsModule,
      FormsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      }),
    ),
    TranslateService,
    TranslateStore,
    KeycloakAngularModule,
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
      deps: [KeycloakService],
    },
  ],
};
